import React, { useCallback, useRef, useState } from 'react';
import { apiRequest } from 'utils/api';

import './registration.css';

function Registration({ setRegistrationOn }) {
    const email = useRef('');
    const password = useRef('');
    const agreement = useRef(false);

    const [error, setError] = useState('');

    const handleRegistration = useCallback(async () => {
        try {
            if (!email.current || !password.current) throw new Error('Уведіть пошту та пароль');
            if (!agreement.current) throw new Error('Погодьтесь з політикою');

            const response = await apiRequest('reg.php', 'POST', {
                inputEmail: email.current,
                inputPassword: password.current,
                agreement: 'Y',
            });

            if (response.status == 'error') throw new Error(response.message);

            setRegistrationOn(false);
        } catch (error) {
            setError(error.message);
        }
    }, []);

    return (
        <div id='registration' style={{ zIndex: 9 }}>
            <div className='top_nav'>
                <div className='button_right' onClick={() => setRegistrationOn(false)}>
                    <svg className='close' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 501 501'>
                        <path
                            className='fil0'
                            d='M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z'
                        />
                    </svg>
                </div>
            </div>
            <h2>Реєстрація</h2>
            <form autoComplete='off'>
                <input
                    name='text'
                    id='reg_email'
                    type='text'
                    placeholder='E-mail'
                    onChange={(e) => (email.current = e.target.value)}
                />
                <input
                    name='password'
                    id='reg_password'
                    type='password'
                    placeholder='Пароль'
                    onChange={(e) => (password.current = e.target.value)}
                />
                <input
                    type='checkbox'
                    id='agreement'
                    onChange={(event) => (agreement.current = event.target.checked)}
                />
                <label htmlFor='agreement'>
                    <span>
                        Підтверджую згоду з{' '}
                        <a
                            href='https://docs.google.com/document/d/1MrkkiOzmrFsZXqFudXYOxNOSVRrs58f_wAnpcAMTBmw/edit'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            політикою конфеденційності
                        </a>{' '}
                        та{' '}
                        <a
                            href='https://docs.google.com/document/d/1VAArlPXcohw8MiDUNPPq6sqGg-zeoFaqIqZOBE-BkuE/edit'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            умовами використання
                        </a>
                    </span>
                </label>
                {error && <span id='reg_error'>{error}</span>}
                <button
                    type='button'
                    onClick={handleRegistration}
                    className='accept shadow radius-max b-grad'
                >
                    <span>Реєстрація</span>
                </button>
            </form>
        </div>
    );
}

export default Registration;
