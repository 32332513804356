import React from "react";
import { useDispatch } from "react-redux";
import "./styles.css";
import { apiRequest } from "utils/api";
import {
    clearUser,
    toggleSettings,
    toggleAuthorize,
  } from "../../features/auth/authSlice";
  import { hideEditProfile } from "features/editProfileSlice/editProfileSlice";

const LightboxDeleteAccount = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    apiRequest("del_user_acc.php", "POST")
      .then((response) => {
        if (response.status === "success") {
          // Дія після успішного видалення акаунта (наприклад, редирект на головну сторінку)
          //window.location.reload(); // або можна зробити редирект на іншу сторінку
          console.log('Дія після успішного видалення акаунта');
          dispatch(clearUser());
          dispatch(toggleAuthorize());
          dispatch(toggleSettings());
          dispatch(hideEditProfile()); // Закриваємо вікно редагування профілю
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error deleting account:", error);
      });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="lightbox delete_my_acc">
      <div className="box">
        <div className="header">
          <span>Видалити акаунт з LetMeStyle</span>
          <div className="close_delete_my_acc" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
              <path
                className="fil0"
                d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
              />
            </svg>
          </div>
        </div>
        <div className="content">
          <span>Ви дійсно бажаєте видалити ваш акаунт?</span>
          <div>
            <input type="hidden" id="trash_post_id" />
            <input type="hidden" id="initiator" />
            <div className="button delete_acc_yes" onClick={handleDelete}>
              Так
            </div>
            <div className="button delete_no" onClick={onClose}>
              Скасувати
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightboxDeleteAccount;
