import Cropper from 'react-easy-crop';

import { useState, useCallback } from 'react';

import { b64toBlob } from 'utils/converter';
import getCroppedImg from 'utils/imageCropper';
import { apiRequest } from 'utils/api';

export default function Result({ result, width, height, setResult, isPhoto, setCameraOn }) {
    const [comment, setComment] = useState('');

    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);
    const [croppedPixels, setCroppedPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });

    const uploadFile = useCallback(async () => {
        const image = await getCroppedImg(result, croppedPixels);
        console.log(image);
        const blob = isPhoto ? b64toBlob(image) : result;

        const file = new File(
            [blob],
            `msr-${new Date().toISOString().replace(/:|\./g, '-')}.${isPhoto ? 'jpg' : 'webm'}`,
            {
                type: isPhoto ? 'image/jpg' : blob.type,
            }
        );

        await apiRequest(`save_${isPhoto ? 'img' : 'video'}.php`, 'POST', {
            description: comment,
            type: isPhoto ? 'photo' : 'video',
            'video-filename': file.name,
            'video-blob': file,
        });

        setCameraOn(false);
    }, [setCameraOn, croppedPixels]);

    return (
        <section className='result'>
            {isPhoto ? (
                <Cropper
                    image={result}
                    aspect={1 / 2}
                    crop={crop}
                    zoom={zoom}
                    cropSize={{ width, height }}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={(_croppedArea, croppedAreaPixel) =>
                        setCroppedPixels(croppedAreaPixel)
                    }
                    zoomWithScroll={true}
                    objectFit={'cover'}
                />
            ) : (
                <video
                    className='video-result'
                    src={URL.createObjectURL(result)}
                    loop
                    autoPlay={true}
                />
            )}
            <div className='splash'></div>
            <div className='top-nav'>
                <img src='/svg/trash-icon.svg' alt='remove' onClick={() => setResult(null)} />

                <button onClick={uploadFile}>Опубліковати</button>
            </div>
            <div className='comment'>
                <h5>Додайте коментар</h5>
                <textarea
                    name='comment'
                    id='comment'
                    style={{ resize: 'none' }}
                    onInput={(event) => setComment(event.target.value)}
                ></textarea>
            </div>
        </section>
    );
}
