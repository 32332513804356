import { createSlice } from '@reduxjs/toolkit';

export const userProfileSlice = createSlice({
    name: 'user',
    initialState: {
        slidesPublisherId: -1,
        slidePage: 0,
        data: null, // Зберігає дані обраного користувача
        showUserProfile: false, // Контролює відображення компонента User
    },
    reducers: {
        toggleUserProfile: (state) => {
            console.log('toggleUser');
            state.showUserProfile = !state.showUserProfile; // Перемикає стан showAuthorize
        },
        setUser: (state, action) => {
            state.data = action.payload; // Зберігає дані користувача
        },
        updatePublisherId: (state, action) => {
            state.slidesPublisherId = action.payload;
        },
        updateSlidePage: (state, action) => {
            state.slidePage = action.payload;
        },
    },
});

export const { toggleUserProfile, setUser, updatePublisherId, updateSlidePage } =
    userProfileSlice.actions;
export default userProfileSlice.reducer;
