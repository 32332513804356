export const b64toBlob = (dataURI) => {
    let byteString = atob(dataURI.split(',')[1]);

    //let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpg' });
};

export const fileToDataURI = (file) =>
    new Promise((resolve) => {
        if (!file) resolve('');
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

export const secondsToFullTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const leftSeconds = seconds - minutes * 60;

    const parsedMinutes = minutes == 0 ? '00' : minutes < 10 ? `0${minutes}` : minutes;
    const parsedSeconds =
        leftSeconds == 0 ? '00' : leftSeconds < 10 ? `0${leftSeconds}` : leftSeconds;

    return parsedMinutes + ':' + parsedSeconds;
};
