import { createSlice } from '@reduxjs/toolkit';

const editProfileSlice = createSlice({
  name: 'editProfile',
  initialState: {
    isVisible: false,
    loading: false,
    error: null,
    profileData: null, // зберігає інформацію про профіль
  },
  reducers: {
    showEditProfile: (state) => {
      state.isVisible = true;
    },
    hideEditProfile: (state) => {
      state.isVisible = false;
    },
    fetchProfileStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProfileSuccess: (state, action) => {
      state.loading = false;
      state.profileData = action.payload; // записуємо отримані дані профілю
    },
    fetchProfileError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  showEditProfile,
  hideEditProfile,
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileError,
} = editProfileSlice.actions;

export default editProfileSlice.reducer;
