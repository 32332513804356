import Cropper from 'react-easy-crop';

import { useEffect, useState, useCallback } from 'react';

import getCroppedImg from 'utils/imageCropper';
import { b64toBlob } from 'utils/converter';
import { apiRequest } from 'utils/api';

import './profile-photo-editor.css';

export default function ProfilePhotoEditor({ photo, setPhoto, refetch, setRefetch }) {
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [zoom, onZoomChange] = useState(1);
    const [croppedPixels, setCroppedPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });

    useEffect(() => {
        setTimeout(() => {
            const editor = document.querySelector('section.photo-editor');
            if (editor) editor.style.animation = 'none';
        }, 300);
    });

    const handleCloseEditor = useCallback(() => {
        const editor = document.querySelector('section.photo-editor');
        if (editor) editor.style.animation = 'editor 250ms ease-in-out reverse forwards';
        setTimeout(() => setPhoto(''), 300);
    }, [setPhoto]);

    const handleFileUpload = useCallback(async () => {
        const image = await getCroppedImg(photo, croppedPixels);

        const file = new File(
            [b64toBlob(image)],
            `msr-${new Date().toISOString().replace(/:|\./g, '-')}.jpg`,
            {
                type: 'image/jpg',
            }
        );

        await apiRequest(`save_avatar.php`, 'POST', {
            avatar_name: file.name,
            avatar_blob: file,
        });

        setRefetch(!refetch);

        handleCloseEditor();
    }, [croppedPixels, handleCloseEditor, refetch, setRefetch]);

    return (
        <section className='photo-editor'>
            <Cropper
                image={photo}
                aspect={1}
                crop={crop}
                zoom={zoom}
                cropShape={'round'}
                cropSize={{ width: 250, height: 250 }}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={(_croppedArea, croppedAreaPixel) =>
                    setCroppedPixels(croppedAreaPixel)
                }
                zoomWithScroll={true}
            />
            <div className='buttons'>
                <div onClick={handleCloseEditor}>
                    <img src='/svg/photo-editor/decline.svg' alt='decline' width={50} height={50} />
                </div>
                <div onClick={handleFileUpload}>
                    <img src='/svg/photo-editor/approve.svg' alt='approve' width={50} height={50} />
                </div>
            </div>
        </section>
    );
}
