import { useRef, useState, useCallback, useEffect } from 'react';

const useRecordHandler = (webcamRef) => {
    const mediaRecorderRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = useState([]);

    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    const [seconds, setSeconds] = useState(0);
    const [timestamp, setTimestamp] = useState(0);

    const calcSeconds = useCallback(() => {
        if (!isRecording && !isPaused) setSeconds(0);
        else if (isRecording) setSeconds(seconds + (Date.now() - timestamp) / 1000);
    }, [seconds, timestamp, isRecording, isPaused]);

    useEffect(() => {
        setTimeout(() => {
            calcSeconds();
            setTimestamp(Date.now());
        }, 75);
    }, [timestamp, isRecording, isPaused]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) setRecordedChunks((prev) => prev.concat(data));
        },
        [setRecordedChunks]
    );

    const start = useCallback(() => {
        if (!webcamRef.current) return;
        setRecordedChunks([]);
        setTimestamp(Date.now());
        setIsRecording(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.start();
    }, [webcamRef, mediaRecorderRef, setRecordedChunks]);

    const pause = useCallback(() => {
        mediaRecorderRef.current.pause();
        setIsRecording(false);
        setIsPaused(true);
    }, [webcamRef, mediaRecorderRef]);

    const resume = useCallback(() => {
        mediaRecorderRef.current.resume();
        setTimestamp(Date.now());
        setIsRecording(true);
        setIsPaused(false);
    }, [webcamRef, mediaRecorderRef]);

    const stop = useCallback(() => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        setIsPaused(false);
    }, [webcamRef, mediaRecorderRef]);

    useEffect(() => {
        if (Math.floor(seconds) == 10) stop();
    }, [seconds]);

    const video = useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: 'video/webm',
            });
            return blob;
        }
        return undefined;
    }, [recordedChunks]);

    const clearVideo = useCallback(() => {
        setRecordedChunks([]);
    }, [recordedChunks]);

    return { isRecording, isPaused, seconds, start, pause, resume, stop, video, clearVideo };
};

export default useRecordHandler;
