import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleAuthorize, setUser, toggleSettings } from "features/auth/authSlice";
import { apiRequest } from "utils/api"; 
import ForgotPassword from "../ForgotPassword/ForgotPassword"; // Import the ForgotPassword component

function Authorize({ setRegistrationOn }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [maskedPassword, setMaskedPassword] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false); // State to show ForgotPassword
  const dispatch = useDispatch();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setMaskedPassword(newPassword.replace(/./g, '\u2022'));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setError(''); // Очистити будь-які попередні помилки

        try {
            const response = await apiRequest('auth.php', 'POST', {
                inputEmail: email,
                inputPassword: password,
            });

            // Якщо сервер повертає токен, зберігаємо його
            if (response.token) {
                localStorage.setItem('token', response.token);
            }

            // Перевірка наявності помилки
            if (response.Error) {
                setError(response.Error);
            } else {
                // Успішна авторизація
                console.log('Авторизація пройшла успішно:', response);
                dispatch(setUser(response)); // Зберігаємо всю відповідь
                dispatch(toggleSettings());
                dispatch(toggleAuthorize());
            }
        } catch (err) {
            setError('Network error or server is down');
            console.error('Помилка при відправці запиту:', err);
        }
    };

    const handleCloseClick = () => {
        dispatch(toggleAuthorize()); // Викликаємо дію для закриття компонента
    };

    return showForgotPassword ? (
    <ForgotPassword setShowForgotPassword={setShowForgotPassword} /> // Render ForgotPassword component
  ) : (
        <div id='authorize'>
            <div className='top_nav'>
                <div className='button_right'>
                    <svg
                        className='close'
                        onClick={handleCloseClick}
                        xmlns='http://www.w3.org/2000/svg'
                        style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
                        viewBox='0 0 501 501'
                    >
                        <path
                            className='fil0'
                            d='M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z'
                        />
                    </svg>
                </div>
            </div>
            <h2>Authorization</h2>
            <form onSubmit={handleFormSubmit}>
                <input
                    name='text'
                    id='auth_email'
                    type='text'
                    placeholder='E-mail'
                    onChange={handleEmailChange}
                />
                <div className='auth_container' style={{ position: 'relative' }}>
                    <input
                        name='auth_password'
                        id='auth_mask'
                        type='text'
                        value={maskedPassword}
                        readOnly
                    />
                    <input
                        name='auth_password'
                        id='auth_password'
                        type='text'
                        placeholder='Password'
                        style={{ position: 'absolute', left: 0 }}
                        onChange={handlePasswordChange}
                    />
                </div>
                <span id='auth_error' style={{ color: 'red' }}>
                    {error}
                </span>
                <button type='submit' className='accept shadow radius-max b-grad'>
                    <span>Login</span>
                </button>
                <div className='additionals'>
                    <div className='signup bb-grad' onClick={() => setRegistrationOn(true)}>
                        Create account
                    </div>
                    <div className='forgot' onClick={() => setShowForgotPassword(true)}>
            Forgot Password
          </div>
                </div>
            </form>
        </div>
    );
}

export default Authorize;
