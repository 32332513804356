import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false
  },
  reducers: {
    // Дія для встановлення стану завантаження
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
});

// Експорт дії setIsLoading для використання в компонентах
export const { setIsLoading } = loadingSlice.actions;

// Дефолтний експорт редюсера для включення в основний редюсер
export default loadingSlice.reducer;
